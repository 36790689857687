<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card no-body>
          <b-card-body>
            <b-row>
              <b-col>
                <v-select
                  id="id_com_brand"
                  v-model="id_com_brand"
                  placeholder="Marka"
                  :options="brands"
                  label="title"
                  :reduce="item => item.id"
                  @input="getCompletedSaleDeliveries"
                />
              </b-col>
              <b-col>
                <b-input-group>
                  <b-form-datepicker
                    id="invoice_date"
                    v-model="invoice_date"
                    v-bind="{labelNoDateSelected: 'Fatura Tarihi',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
                    locale="tr"
                    start-weekday="1"
                    @input="getCompletedSaleDeliveries"
                  />
                  <b-input-group-append v-if="invoice_date">
                    <b-button

                      variant="warning"
                      @click="getCompletedSaleDeliveries(1)"
                    >
                      <feather-icon icon="XCircleIcon" />
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-col>
              <b-col>
                <b-input-group>
                  <b-form-datepicker
                    id="delivery"
                    v-model="delivery"
                    v-bind="{labelNoDateSelected: 'Teslim Tarihi',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
                    locale="tr"
                    start-weekday="1"
                    @input="getCompletedSaleDeliveries"
                  />
                  <b-input-group-append v-if="delivery">
                    <b-button

                      variant="warning"
                      @click="getCompletedSaleDeliveries(2)"
                    >
                      <feather-icon icon="XCircleIcon" />
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-col>
            </b-row>
          </b-card-body>
          <template v-if="dataList.length > 0">
            <b-table
              class="font-small-3"
              responsive="sm"
              :fields="fields"
              :items="dataList"
              striped
              hover
            >
              <template #cell(customer)="data">
                <div class="d-flex">
                  <div class="photo">
                    <div
                      v-if="data.item.photo"
                      class="d-flex align-items-center justify-content-center"
                    >
                      <b-img
                        :src="photoURL + data.item.photo"
                        fluid
                        @click="setModal(data.item.photo)"
                      />
                    </div>
                    <div
                      v-else
                      class="bg-light d-flex align-items-center justify-content-center"
                    >
                      <FeatherIcon icon="CameraIcon" />
                    </div>
                  </div>
                  <div>
                    <div>{{ data.item.customer }}</div>
                    <div
                      v-if="data.item.company_name"
                      class="font-small-1 text-warning"
                    >
                      {{ data.item.company_name }}
                    </div>
                  </div>
                </div>
              </template>
              <template #cell(brand)="data">
                <div>{{ data.item.brand }} {{ data.item.model }}</div>
                <div class="font-small-2 text-danger">
                  {{ data.item.license_plate }}
                </div>
              </template>
              <template #cell(delivery)="data">
                <div>{{ moment(data.item.delivery).format('ll') }}</div>
                <div class="font-small-2 text-primary">
                  {{ data.item.location }}
                </div>
              </template>
              <template #cell(sales_invoice)="data">
                <div>{{ moment(data.item.sales_invoice).format('MMMM') }} {{ moment(data.item.sales_invoice).format('YYYY') }}</div>
              </template>
              <template #cell(control)="data">
                <b-button
                  variant="primary"
                  size="sm"
                  :to="'/sale_deliveries/completed/view/' + data.item.id"
                >
                  Detay
                </b-button>
              </template>
            </b-table>
            <b-card-footer>
              <b-pagination
                v-model="currentPage"
                :total-rows="completedSaleDeliveriesCount"
                :per-page="perPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-card-footer>
          </template>
          <b-alert
            v-else
            show
            variant="info"
            class="m-2"
          >
            <div class="alert-body text-center">
              İlgili sonuç bulunamadı.
            </div>
          </b-alert>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      v-model="modalData.status"
      hide-footer
      size="lg"
    >
      <b-img
        :src="photoURL + modalData.photo"
        fluid
      />
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BCardFooter,
  BPagination,
  BButton,
  BCardBody,
  BImg,
  BModal,
  BInputGroupAppend,
  BInputGroup,
  BFormDatepicker, BAlert,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'Index',
  components: {
    BAlert,
    BFormDatepicker,
    BInputGroup,
    BInputGroupAppend,
    BCard,
    BTable,
    BRow,
    BCol,
    BCardFooter,
    BPagination,
    BButton,
    BCardBody,
    BImg,
    BModal,
    vSelect,
  },
  data() {
    return {
      photoURL: `${this.$store.state.app.baseURL}/media/sales/delivery/`,
      id_com_brand: null,
      invoice_date: null,
      delivery: null,
      currentPage: 1,
      perPage: 50,
      search: '',
      modalData: {
        status: false,
        photo: null,
      },
      fields: [
        {
          key: 'customer',
          label: 'MÜŞTERİ',
          thStyle: { verticalAlign: 'middle', width: '350px' },
        },
        {
          key: 'brand',
          label: 'ARAÇ BİLGİSİ',
          thStyle: { verticalAlign: 'middle' },
        },
        {
          key: 'delivery',
          label: 'TESLİMAT',
          thStyle: { verticalAlign: 'middle' },
        },
        {
          key: 'sales_invoice',
          label: 'Fatura Dönemi',
          thStyle: { verticalAlign: 'middle' },
        },
        {
          key: 'control',
          label: '',
          thClass: 'width-100',
        },
      ],
      dataQuery: {
        select: [
          'com_sale_delivery.id AS id',
          'com_sale_delivery.photo AS photo',
          'com_customer.name AS customer',
          'com_customer.company_name AS company_name',
          'com_brand.name AS brand',
          'com_model.title AS model',
          'com_fuel.title AS fuel',
          'com_gear.title AS gear',
          'com_sale_delivery.license_plate AS license_plate',
          'com_sale_delivery.delivery AS delivery',
          'com_location.title AS location',
          '_mssql_aracsatis.invoice_date AS sales_invoice',
          'com_sale_delivery.status AS status',
        ],
        limit: 50,
        start: 0,
      },
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['completedSaleDelivery/getCompletedSaleDeliveries']
    },
    completedSaleDeliveriesCount() {
      return this.$store.getters['completedSaleDelivery/getCompletedSaleDeliveriesCount']
    },
    brands() {
      return this.$store.getters['brands/getBrands']
    },
  },
  watch: {
    currentPage(val) {
      const page = (val - 1) * 50
      this.pagination(page)
    },
  },
  created() {
    this.getCompletedSaleDeliveries()
    this.getBrands()
  },
  methods: {
    getCompletedSaleDeliveries(invReset = null) {
      if (invReset === 1) {
        this.invoice_date = null
      }
      if (invReset === 2) {
        this.delivery = null
      }
      if (this.id_com_brand) {
        this.dataQuery.id_com_brand = this.id_com_brand
      } else {
        this.dataQuery.id_com_brand = null
      }
      if (this.invoice_date) {
        this.dataQuery.invoice_date = this.invoice_date
      } else {
        this.dataQuery.invoice_date = null
      }
      if (this.delivery) {
        this.dataQuery.delivery = this.delivery
      } else {
        this.dataQuery.delivery = null
      }
      this.$store.dispatch('completedSaleDelivery/completedSaleDeliveriesList', this.dataQuery)
    },
    getBrands() {
      this.$store.dispatch('brands/brandsList', {
        select: ['com_brand.id AS id', 'com_brand.name AS title'],
        where: {
          'com_brand.salestatus': 1,
        },
      })
    },
    pagination(page) {
      this.dataQuery.start = page
      if (this.dataQuery.start === page) {
        this.getCompletedSaleDeliveries()
      }
    },
    setModal(photo) {
      this.modalData.status = true
      this.modalData.photo = photo
    },
  },
}
</script>

<style scoped>
.photo div{
  width: 100px !important;
  height: 100px;
  overflow: hidden;
  margin-right: 5px;
  border: 1px solid #cccccc;
  border-radius: 5px;
  background: #ffffff;
}
.photo div .modal-button{
  position: absolute;
  z-index: 1000;
  margin-bottom: -80px;
}
</style>
